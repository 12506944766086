<template>
  <div class="row">
    <!-- <div class="col-xl-3 col-lg-12">
      <div class="">
        <a-menu mode="vertical" style="width: 100%;" class="card shadow-sm pt-2 pb-4 px-3" :selected-keys="[selectedMenu]" >
            <a-menu-item-group>
              <a-menu-item id="menu-item" @click="() => { selectedMenu = 0 }" class="m-2" :key="0">
                <a-icon type="user" /> Personal Information
              </a-menu-item>
              <a-menu-item id="menu-item" @click="() => { selectedMenu = 1 }" class="m-2" :key="1">
                <a-icon type="security-scan" /> Edit Password
              </a-menu-item>
            </a-menu-item-group>
          </a-menu>
      </div>
    </div> -->
    <div class="col-lg-12">
      <div class="card rounded-0">
          <a-menu :selected-keys="[selectedMenu]" mode="horizontal">
              <a-menu-item id="menu-item" @click="() => { selectedMenu = 1; $router.push('/profile/account') }" class="m-2" :key="1">
                <a-icon type="security-scan" /> Edit Password
              </a-menu-item>
          </a-menu>
        <div class="card-body">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PersonalInfo from './content/personalinfo'
// import EditPassword from './content/editpassword'
export default {
  data () {
    return {
      selectedMenu: 0,
    }
  },
  // components: {
  //   PersonalInfo,
  //   EditPassword,
  // },
}
</script>

<style>
  /* #menu-item:hover {
    border-bottom: 2px solid lightskyblue;
  } */
</style>
